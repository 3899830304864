<template>
    <div class="page_container">
        <div class="page_head">
            <div class="page_head-title">MOTIVATIONAL PHRASES</div>
            <b-button class="page_head-btn" id="add_phrase-btn" @click="phraseModal('new', {})">New Phrase</b-button>
        </div>
        <div class="page_content">
            <div class="page_title">Welcome {{logUserData.name}}</div>
            <div class="page_subtitle">Motivational phrases</div>
            <div class="page_cards">
                <div class="flip-card" v-for="card in motivation_phrases" :key="card.id">
                    <div class="flip-card-inner" @click="phraseModal('edit', card)">
                            <div>{{card.title}}</div>
                        <div class="remove_btn" @click.stop="removePhrase(card.id)"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--add phrase modal-->
        <b-modal ref="phrase-modal" centered size="md" hide-header hide-footer>
            <div class="modal_content">
                <div class="modal_title">
                    {{modalData.title}}
                </div>
                <div class="modal_input">
                    <b-form-textarea
                            id="textarea"
                            v-model="newPhrase.title"
                            placeholder="Enter something..."
                            rows="3"
                            max-rows="6"
                    ></b-form-textarea>
<!--                    <b-form-input v-model="newPhrase"></b-form-input>-->
                </div>

                <div class="modal_btn">
                    <b-button class="cancel_btn mx-2" @click="phraseModal()">Cancel</b-button>
                    <b-button v-if="modalData.status==='new'" class="add_btn mx-2" @click="addPhrase()">{{modalData.btn_text}}</b-button>
                    <b-button v-if="modalData.status==='edit'" class="add_btn mx-2" @click="editPhrase()">{{modalData.btn_text}}</b-button>
                </div>

            </div>
        </b-modal>
        <!--add phrase modal-->
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "MotivationalPhrases",
        data() {
            return {
                newPhrase: {
                    id: null,
                    title: null,
                    description: null
                },
                modalData: {
                    status: null,
                    title: null,
                    btn_text: null
                },
                motivation_phrases: []
            }
        },
        created() {
            this.getAllPhrases()
        },
        computed: {
            ...mapGetters(['logUserData'])
        },
        methods: {
            ...mapActions(['getPhrases', 'addNewPhrase', 'deletePhrase', 'updatePhrase']),
            getAllPhrases() {
                this.getPhrases()
                    .then(res => {
                        this.motivation_phrases = res.data
                    })
            },
            phraseModal(status, data) {
                if(status == 'new') {
                    this.newPhrase.title = null
                    this.newPhrase.description = null
                    this.modalData.title =  'Add New Phrase'
                    this.modalData.btn_text =  'Add phrase'
                    this.modalData.status =  'new'
                } else if (status == 'edit'){
                    this.modalData.title =  'Edit Phrase'
                    this.modalData.btn_text =  'Update'
                    this.modalData.status =  'edit'
                    this.newPhrase.title = data.title
                    this.newPhrase.id = data.id
                }
                this.$refs['phrase-modal'].toggle('#add_phrase-btn')
            },
            addPhrase() {
                if(this.newPhrase.title) {
                    this.addNewPhrase(this.newPhrase)
                        .then(res => {
                            this.getAllPhrases()
                            this.$refs['phrase-modal'].toggle('#add_phrase-btn')
                        })
                }
            },
            removePhrase(id) {
                this.deletePhrase(id)
                .then(res => {
                    this.getAllPhrases()
                })
            },
            editPhrase() {
                this.updatePhrase(this.newPhrase)
                .then(res => {
                    this.getAllPhrases()
                    this.$refs['phrase-modal'].toggle('#add_phrase-btn')
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_container {
            width: 100%;
        }
        &_head {
            width: 100%;
            height: 73px;
            background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;
            position: sticky;
            top: 0;
            z-index: 3;
            &-title {
                color: #FFFFFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            &-btn {
                height: 44px;
                border-radius: 12px;
                border: 1px solid #00A99D;
                background: #00A99D;
                color: #FFFFFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 25px;
                cursor: pointer;
                &:active,
                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
        }
        &_content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 70px;
        }
        &_title {
            color: #11334D;
            font-size: 38.059px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        &_subtitle {
            color: #11334D;
            font-size: 38.059px;
            font-style: italic;
            font-weight: 500;
            line-height: normal;
        }
        &_cards {
            display: flex;
            /*justify-content: center;*/
            flex-wrap: wrap;
            padding: 50px 0;
            .flip-card {
                margin: 23px 27px;
                background-color: transparent;
                width: 236px;
                height: 200px;
            }

            .flip-card-inner {
                position: relative;
                border-radius: 12px;
                width: 100%;
                height: 100%;
                filter: drop-shadow(0px 4px 12px #004475);
                border: 5px solid rgba(0, 82, 140, 1);
                background-color: #ffffff;
                color: #11334D;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 20px;
                &:hover {
                    & .remove_btn {
                        transition: .4s;
                        visibility: visible;
                        opacity: 1;
                    }
                }
                & .remove_btn {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    width: 25px;
                    height: 25px;
                    background: url("~@/assets/icons/deactive.svg") no-repeat center;
                    background-size: cover;
                    cursor: pointer;
                    transition: .4s;
                    visibility: hidden;
                    opacity: 0;
                }
            }

        }
    }
    .modal {
        &_content {
            padding: 10px 20px;
        }

        &_title {
            color: #11334D;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 10px 0;
        }

        &_input {
            margin: 10px 0;
            & textarea {
                border: 1px solid #11334D;
                border-radius: 12px;
                resize: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }

            & input {
                border: 1px solid #11334D;

                &::placeholder {
                    color: #11334D;
                    font-size: 18px;
                    font-style: italic;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

        &_btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 20px 0 10px;

            & .cancel_btn,
            & .add_btn {
                height: 40px;
                border-radius: 12px;
                border: 1px solid #FFFFFF;
                background: #00A99D;
                color: #FFFFFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 20px;
                cursor: pointer;
            }
        }
    }
</style>
